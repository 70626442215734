<template>
  <v-dialog
    v-model="dialog"
    v-if="$store.getters['auth/isLoggedIn']"
    width="300"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        small
        icon
        v-on="on"
        @click="form.customer_debit = appointment.customer_debit"
      >
        <v-icon small>mdi-plus-circle</v-icon>
      </v-btn>
    </template>
    <v-card id="select_token_payment">
      <v-card-title style="text-decoration: none !important">
        {{ $t("add", { name: $t("expenses.pendings_customer") }) }}
      </v-card-title>

      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <form
        action
        data-vv-scope="pending-form"
        @submit.prevent="validateForm('pending-form')"
      >
        <v-card-text>
          <v-row>
            <v-col class="py-0">
              <label>{{ $t("expenses.pendings") }}</label>
              <v-text-field
                outlined
                dense
                v-model="form.customer_debit"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn outlined height="30" @click="dialog = false">
            {{ $t("cancel", { name: "" }) }}
          </v-btn>
          <v-btn
            color="primary"
            type="submit"
            elevation="0"
            style="
              width: 100px !important;
              color: #363533;
              height: 25px;
              padding-top: 10px !important;
            "
          >
            <v-icon small style="margin-right: 5px" size="14px">$save</v-icon>
            {{ $t("save", { name: $t("") }) }}
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      form: {},
    };
  },
  props: ["appointment"],
  methods: {
    ...mapActions("appointments", ["addPendingCustomer"]),
    async validateForm(scope) {
      this.errors.clear();
      let result = await this.$validator.validateAll(scope);
      if (result) {
        this.addPendingCustomer({
          id: this.appointment.id,
          form: this.form,
        }).then((result) => {
          if (result.success) {
            this.dialog = false;
            this.$emit("update");
          }
        });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
label
  display: block
  height: 30px
</style>
